import './mira.css';
import {
    CloseOutlined,
    CloseSquareOutlined,
    CloudDownloadOutlined,
    CommentOutlined,
    DeleteOutlined,
    FileImageOutlined,
    FilePdfOutlined,
    InboxOutlined,
    LeftOutlined,
    LoadingOutlined,
    PlusOutlined,
    PlusSquareOutlined,
    SendOutlined,
    AudioOutlined,
    DownOutlined,
    ShoppingCartOutlined,
    FileDoneOutlined,
    ProfileOutlined,
    MinusOutlined
} from '@ant-design/icons';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import { Button, message, Modal, Popover, Spin, Switch, Select, Table, Input } from 'antd';
import ApiUrl from '../../network';
import normFile from '../../imageToBucket';
import Dragger from 'antd/es/upload/Dragger';
import dayjs from 'dayjs';
import { decrypt, encrypt } from '../../utils/encrypt';

const miraURL = ApiUrl.MiraUrl;
const country = localStorage.getItem('country') || 'Ghana';
const baseUrl = `${miraURL}/api/v1/whatsapp`;
const socketUrl = `${miraURL}/`; // WebSocket endpoint

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');
const logo = '../../garagelogo.png';

export const Index = () => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false);
    const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
    const [receiptLoading, setReceiptLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [socket, setSocket] = useState(null);
    const [messageType, setMessageType] = useState('text');
    const [imageUrl, setImageUrl] = useState('');
    const [fileList, setFileList] = useState({});
    const [replyTo, setReplyTo] = useState({
        _id: null,
        profile: null,
        content: null,
        whatsapp_id: null
    });
    const [switchChat, setSwitchChat] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState({});
    const chatBoxRef = useRef(null);
    const [imageModal, setImageModal] = useState(false);
    const [replyType, setReplyType] = useState('text');
    const [mediaContentOpen, setMediaContentOpen] = useState(false);
    const [accept, setAccept] = useState('image/*');
    const [pdfPreview, setPdfPreview] = useState(null);
    const [isSenderListVisible, setSenderListVisible] = useState(true);
    const [isChatBoxVisible, setChatBoxVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [chatMenuVisible, setChatMenuVisible] = useState(false);
    const [chatFilter, setChatFilter] = useState('all');
    const [customerOptions, setCustomerOptions] = useState(false);
    const [placeOrder, setPlaceOrder] = useState(false);
    const [assignTask, setAssignTask] = useState(false);
    const [arrow, setArrow] = useState('Show');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState([]);
    const [productType, setProductType] = useState([]);
    const [tab, setTab] = useState('products');
    const [customerName, setCustomerName] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [customerLocation, setCustomerLocation] = useState('');
    const [orderNo, setOrderNo] = useState('');
    // eslint-disable-next-line camelcase
    let userHolder = '';

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${baseUrl}/users?country=${country}`, config);
            const data = await response.json();

            // Add random image URL to each user
            const usersWithImages = data.users.map((user, index) => ({
                ...user,
                imageUrl: `https://api.dicebear.com/9.x/pixel-art/svg?seed=${index}&r=50&size=100`
            }));

            setUsers(usersWithImages);
            setLoading(false);
        } catch (error) {
            toast.error(error.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const setupSocket = () => {
        const newSocket = io(socketUrl, {
            transports: ['websocket'],
            path: '/socket.io'
        });

        newSocket.on('connect', () => {});

        newSocket.on('mira_chat', (data) => {
            // Transform message content
            const transformedMessage = {
                phone: data.phone,
                content: data?.content?.body,
                image: data?.content?.link,
                type: data?.content?.type,
                isSent: data?.agent === 'mira' || data?.agent === 'staff',
                agent: data?.agent,
                user: selectedUser?.profile,
                createdAt: data?.createdAt
            };

            setMessages((prevMessages) => [...prevMessages, transformedMessage]);
            // Update user's last message timestamp
            setUsers((prevUsers) => {
                const updatedUsers = prevUsers.map((user) => {
                    if (user.phone === data.phone) {
                        return {
                            ...user,
                            lastMessageTime: data?.createdAt // Update the last message time
                        };
                    }
                    return user;
                });
                // Sort users by last message time, most recent first
                return updatedUsers.sort((a, b) => {
                    const dateA = new Date(a.lastMessageTime || 0);
                    const dateB = new Date(b.lastMessageTime || 0);
                    return dateB - dateA;
                });
            });
        });

        newSocket.on('disconnect', () => {
            // console.log('Socket.IO disconnected');
        });

        newSocket.on('connect_error', (error) => {
            // console.log('Socket.IO connect_error:', error);
        });

        setSocket(newSocket);
    };

    const sendMessage = () => {
        if ((socket && message.trim()) || !message) {
            const newMessage = {
                phone: selectedUser.phone,
                content: {
                    body: message,
                    type: messageType, // 'text' or 'image',
                    url: messageType === 'image' || messageType === 'document' ? imageUrl : ''
                },
                user_id: localStorage.getItem('userId'),
                replyTo: replyTo.whatsapp_id
            };
            socket.emit('mira_chat', newMessage);

            console.log(newMessage, 'newMessage');

            // Transform message content
            const transformedMessage = {
                phone: selectedUser.phone,
                content: message,
                type: messageType,
                isSent: 'mira' || 'staff',
                agent: 'staff',
                image: messageType === 'image' ? imageUrl : null,
                user: `${localStorage.getItem('userFirstName')} ${localStorage.getItem('userLastName')}`,
                createdAt: new Date().toISOString()
            };

            setMessages((prevMessages) => [...prevMessages, transformedMessage]);

            // eslint-disable-next-line no-use-before-define
            closePopover();

            setMessage('');
            setImageUrl('');
            setFileList({});
            setImageModal(false);
            setReplyTo({ _id: null, profile: null, content: null });
        }
    };

    const takeOverChat = async (phone) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const payload = {
                phone
            };
            const response = await fetch(`${baseUrl}/staff-take-over`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            toast(data.message);
            setSwitchChat(true);
        } catch (error) {
            //  console.log(error);
        }
    };
    const miraOverChat = async (phone) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const payload = {
                phone
            };
            const response = await fetch(`${baseUrl}/mira-take-over`, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify(payload)
            });
            const data = await response.json();

            toast(data.message);
            setSwitchChat(false);
        } catch (error) {
            //  console.log(error);
        }
    };

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            if (window.innerWidth > 768) {
                // For larger screens, scroll to the bottom
                chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
            } else {
                // On mobile, directly show the last message without smooth scrolling
                const lastMessageElement = chatBoxRef.current.lastElementChild;
                if (lastMessageElement) {
                    lastMessageElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    };

    const fetchUserMessages = async (phone, index) => {
        try {
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${baseUrl}/user?phone=${phone}`, config);
            const data = await response.json();

            // Add random image URL to each user
            const userWithImage = {
                ...data,
                imageUrl: `https://api.dicebear.com/9.x/pixel-art/svg?seed=${index}&r=50&size=100`
            };

            setSelectedUser(userWithImage);
            userHolder = data.profile;

            // Fetch messages

            // Transform message content
            const transformedMessages = data.messages.map((chat) => {
                let messageContent = chat.content.body;
                if (typeof messageContent === 'object') {
                    messageContent = messageContent.text.body;
                }
                let user;
                if (chat.agent === 'staff') {
                    user = `${chat.user?.firstName || ''} ${chat.user?.lastName || ''}`;
                } else if (chat.agent === 'mira') {
                    user = 'mira';
                } else {
                    user = data.profile;
                }

                return {
                    _id: chat._id,
                    phone: chat.phone,
                    content: messageContent,
                    image: chat?.content?.url,
                    type: chat.content.type,
                    isSent: chat.agent === 'mira' || chat.agent === 'staff',
                    agent: chat.agent,
                    createdAt: chat.createdAt,
                    profile: chat.agent === 'user' ? data.profile : 'mira',
                    whatsapp_id: chat.whatsapp_id,
                    user
                };
            });

            // Sort messages by date
            const sortedMessages = transformedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

            setMessages(sortedMessages);

            // await takeOverChat(phone);

            scrollToBottom();
        } catch (error) {
            // console.log(error);
        }
    };

    const fetchProducts = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${ApiUrl.BaseUrl}/order/v2/allparts`, config);
            const data = await response.json();
            const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
            setProducts(decryptedData);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchPartType = async () => {
        try {
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const response = await fetch(`${ApiUrl.BaseUrl}/order/v2/orderstatuspartypes`, config);
            const data = await response.json();
            const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
            setProductType(decryptedData?.parttypes);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (phoneNumber.phone) {
            fetchUserMessages(phoneNumber.phone, phoneNumber.index);
        }
    }, [phoneNumber.phone, switchChat]);

    useEffect(() => {
        fetchProducts();
        fetchPartType();
    }, []);

    useEffect(() => {
        fetchUsers().then((r) => r);
    }, []);

    useEffect(() => {
        setupSocket();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, []); // Empty dependency array means this effect runs once
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const onChange = (checked) => {
        setSwitchChat(checked);
        if (checked) {
            miraOverChat(phoneNumber?.phone).then((r) => r);
        } else {
            takeOverChat(phoneNumber?.phone).then((r) => r);
        }
    };

    const handleChatPopClick = (id) => {
        if (open === id) {
            setOpen(null);
            document.getElementById('chat-box-message').classList.remove('no-scroll');
        } else {
            setOpen(id);
            document.getElementById('chat-box-message').classList.add('no-scroll');
        }
    };

    const closePopover = () => {
        setOpen(false);
        document.getElementById('chat-box-message').classList.remove('no-scroll');
    };

    const replyToMessage = (message) => {
        setReplyTo({
            _id: message._id,
            profile: message.profile,
            content: message.content,
            whatsapp_id: message.whatsapp_id
        }); // Set the message to reply to
        closePopover(); // Close the popover
    };

    const actionContent = (message) => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                alignItems: 'left',
                textAlign: 'left',
                backgroundColor: '#f9f9f9',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                width: '200px'
            }}
        >
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setReplyType('text');
                    replyToMessage(message);
                }}
            >
                Reply <CommentOutlined />
            </Button>
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setReplyType('image');
                    setImageUrl(message.image);
                    setImageModal(true);
                    closePopover();
                    replyToMessage(message);
                }}
            >
                Image <FileImageOutlined />
            </Button>
        </div>
    );

    // eslint-disable-next-line consistent-return
    const imageUploadRequest = async (file) => {
        try {
            console.log(file, 'file');
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);
            if (compressedImage) {
                setFileList({ uid: file.uid, url: compressedImage, name: file.name });
                setImageUrl(compressedImage);
                setLoading(false);
                return {
                    status: 'done',
                    url: compressedImage
                };
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    };

    const handleDelete = (file) => {
        setFileList({});
    };

    const props = {
        name: 'file',
        multiple: false,
        accept,
        customRequest: ({ file, onSuccess, onError }) => {
            imageUploadRequest(file).then(onSuccess).catch(onError);
        },
        onChange(info) {
            const { status, uid } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                // console.log(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                // console.log(`${info.file.name} file upload failed.`);
            } else if (status === 'removed') {
                handleDelete(info.file);
            }
        },
        onRemove: (file) => {
            handleDelete(file);
        }
    };

    const mediaContent = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'left',
                alignItems: 'left',
                textAlign: 'left',
                backgroundColor: '#f9f9f9',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                width: '150px'
            }}
        >
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setAccept('application/pdf');
                    setMessageType('document');
                    setImageModal(true);
                    closePopover();
                }}
            >
                Document <FilePdfOutlined />
            </Button>
            <Button
                style={{
                    marginBottom: '5px',
                    backgroundColor: 'transparent',
                    border: 'none',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                type="button"
                onClick={() => {
                    setAccept('image/*');
                    setMessageType('image');
                    setImageModal(true);
                    closePopover();
                }}
            >
                Image <FileImageOutlined />
            </Button>
        </div>
    );
    // Handle resizing for mobile screens
    const handleResize = () => {
        if (window.innerWidth < 768) {
            if (isChatBoxVisible) {
                setSenderListVisible(false);
                document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'none');
                scrollToBottom();
            } else {
                setSenderListVisible(true);
                document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
            }
        } else {
            setSenderListVisible(true);
            setChatBoxVisible(true);
            document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
            scrollToBottom();
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        scrollToBottom();
        return () => window.removeEventListener('resize', handleResize);
    }, [isChatBoxVisible]);

    const showSenderList = () => {
        setSenderListVisible(true);
        setChatBoxVisible(false);
        document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'none');
    };

    const hideSenderList = () => {
        setSenderListVisible(false);
        setChatBoxVisible(true);
        document.querySelector('.MuiPaper-root')?.style.setProperty('display', 'flex');
        scrollToBottom();
    };

    // Filter user based on the search term
    const filteredUsers = users.filter((user) => user.profile.toLowerCase().includes(searchTerm.toLowerCase()));

    // placing order
    const placeOrderFunction = async () => {
        try {
            setPlaceOrderLoading(true);
            const userInfo = localStorage.getItem('userToken');
            if (!userInfo) {
                toast.error('User not authenticated');
                return;
            }
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };
            const localId = Math.random().toString(36).substring(2, 15);
            setOrderNo(localId);
            const orderData = {
                customerName,
                orderNotes: 'Order placed via chat',
                local_id: localId,
                phoneNumber: customerPhone,
                location: customerLocation,
                src: 'Web',
                items: selectedProducts.map((product) => ({
                    skuId: product._id,
                    qty: product.qty || 1,
                    partType: 'Pieces'
                }))
            };

            // Optional: Add lat and lng if available
            if (customerLocation.lat && customerLocation.lng) {
                orderData.lat = customerLocation.lat;
                orderData.lng = customerLocation.lng;
            }

            console.log('Placing order with data:', orderData);

            const datas = orderData;
            const payload = encrypt(JSON.stringify(datas), keyResult, ivResult);

            const url = `${ApiUrl.BaseUrl}/order/v2/placemiraorder`;
            const response = await fetch(url, {
                method: 'POST',
                headers: config.headers,
                body: JSON.stringify({ payload })
            });
            const result = await response.json();
            console.log(result);
            if (result.status === true) {
                toast.success(result.message);
                setPlaceOrderLoading(false);
                setTab('order-receipt');
                toast.success(result.message);
            } else {
                toast.error(result.message);
                setPlaceOrderLoading(false);
            }
        } catch (error) {
            console.log(error);
            setPlaceOrderLoading(false);
        }
    };

    const mergedArrow = useMemo(() => {
        if (arrow === 'Hide') {
            return false;
        }
        if (arrow === 'Show') {
            return true;
        }
        return {
            pointAtCenter: true
        };
    }, [arrow]);

    return (
        <div className="chat-inbox-container">
            {isSenderListVisible && (
                <div className="senderList">
                    <div className="search-containter">
                        <select value={chatFilter} onChange={(e) => setChatFilter(e.target.value)} className="chat-filter-select">
                            <option value="all">Show all chats</option>
                            <option value="read">Read chats</option>
                            <option value="unread">Unread chats</option>
                        </select>
                        <input
                            type="text"
                            placeholder="Search customer "
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                    </div>
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                            }}
                        >
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            fontSize: 48,
                                            color: '#FF740F'
                                        }}
                                        spin
                                    />
                                }
                            />
                        </div>
                    ) : (
                        <div>
                            {filteredUsers.map((user, index) => {
                                const lastMessage = messages.filter((msg) => msg.phone === user.phone).slice(-1)[0]?.content || user.phone;
                                const hasNewMessage = messages.some(
                                    (msg) => msg.phone === user.phone && !msg.isSent && msg.agent !== 'user'
                                );

                                return (
                                    <div
                                        className="sender"
                                        key={user._id}
                                        onClick={() => {
                                            if (window.innerWidth < 768) {
                                                hideSenderList();
                                            }
                                            setSelectedUser(user.profile);
                                            setPhoneNumber({ phone: user.phone, index });
                                            fetchUserMessages(user.phone, index);
                                        }}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => fetchUserMessages(user.phone, index)}
                                    >
                                        <div className="sender-avatar">{user?.profile?.slice(0, 2).toUpperCase()}</div>
                                        <div className="sender-content">
                                            <div className="senderName">{user?.profile}</div>
                                            <div className="senderMessage">
                                                {hasNewMessage ? (
                                                    <strong className="new_message_indicator">New Message</strong>
                                                ) : (
                                                    lastMessage
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}

            {selectedUser && isChatBoxVisible && (
                <div className="chatBox">
                    <div className="chat-box-header">
                        <div className="chat-box-header-content">
                            <span className="back">
                                <LeftOutlined onClick={showSenderList} />
                            </span>
                            <div className="sender-avatar">{selectedUser?.profile?.slice(0, 2).toUpperCase()}</div>
                            <div className="ml-5">
                                <p className="user-name">
                                    <strong>{selectedUser?.profile || 'Loading...'}</strong>
                                </p>
                                <small className="senderMessage">{selectedUser?.phone}</small>
                            </div>
                        </div>
                        <div className="customer-option-container">
                            <Popover
                                trigger="click"
                                placement="bottom"
                                content={
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
                                        <div
                                            key="assign-task"
                                            style={{
                                                display: 'flex',

                                                justifyContent: 'space-between',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <ProfileOutlined style={{ marginRight: '5px', fontSize: '20px' }} />
                                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Assign task</p>
                                        </div>
                                        <div
                                            key="customer-cart"
                                            style={{
                                                display: 'flex',

                                                justifyContent: 'space-between',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <FileDoneOutlined style={{ marginRight: '5px', fontSize: '20px' }} />
                                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Customer cart</p>
                                        </div>
                                        <div
                                            key="place-order"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setPlaceOrder(true)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' || e.key === ' ') {
                                                    setPlaceOrder(true);
                                                }
                                            }}
                                            role="button"
                                            tabIndex={0}
                                            aria-label="Place order"
                                        >
                                            <ShoppingCartOutlined style={{ marginRight: '5px', fontSize: '20px' }} />
                                            <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Place order</p>
                                        </div>
                                    </div>
                                }
                                arrow={mergedArrow}
                            >
                                <Button
                                    style={{
                                        backgroundColor: '#0A2938',
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    Customer options <DownOutlined style={{ marginLeft: '5px', fontSize: '14px', marginTop: '3px' }} />
                                </Button>
                            </Popover>
                        </div>
                        {/* <div className="chat-box-header-delete">
                            <Switch defaultChecked={switchChat} checkedChildren="Mira" onChange={onChange} />
                        </div> */}
                    </div>

                    <div className="chat-box-body">
                        <div id="chat-box-message" className="chat-box-message" ref={chatBoxRef}>
                            {messages
                                .filter((message) => message.phone === phoneNumber.phone)
                                .reduce((acc, message, index, array) => {
                                    const messageDate = dayjs(message.createdAt);
                                    const prevMessage = array[index - 1];
                                    const prevMessageDate = prevMessage ? dayjs(prevMessage.createdAt) : null;

                                    if (!prevMessageDate || !messageDate.isSame(prevMessageDate, 'day')) {
                                        acc.push(
                                            <div key={`date-${message._id}`} className="message-date-separator">
                                                <span>{messageDate.format('MMMM D, YYYY')}</span>
                                            </div>
                                        );
                                    }

                                    acc.push(
                                        <Popover
                                            content={actionContent(message)}
                                            key={message._id}
                                            className="popoverChat"
                                            trigger="click"
                                            open={open === message._id}
                                            onOpenChange={() => handleChatPopClick(message._id)}
                                            placement={open === message._id ? 'top' : 'right'}
                                            style={{ marginBottom: '20px' }}
                                        >
                                            {/* <div className={message.isSent ? 'message-user-send' : 'message-user-recieve'}>
                                                {message?.user && (
                                                    <span className="message-info">
                                                        <small style={{ color: '#000', fontSize: '12px' }}>{message.user}</small>
                                                    </span>
                                                )}
                                            </div> */}
                                            <div className={message.isSent ? 'chat-box-message-send' : 'chat-box-message-recieve'}>
                                                <div className="message">
                                                    <div>
                                                        {message?.user && (
                                                            <span>
                                                                <small
                                                                    style={{
                                                                        color: '#819AFF',
                                                                        fontSize: '12px',
                                                                        marginBottom: '5px',
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                >
                                                                    {message.user}
                                                                </small>
                                                                <br />
                                                            </span>
                                                        )}
                                                        {message.content && (
                                                            <span className="mt-4">
                                                                <p> {message.content}</p>
                                                            </span>
                                                        )}
                                                        {message.type === 'image' && message.image && (
                                                            <span
                                                                className="mt-4 mb-4"
                                                                role="button"
                                                                tabIndex={0}
                                                                aria-label="View image"
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                                        // Trigger any action on key press if necessary
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={message.image}
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = 'https://via.placeholder.com/150';
                                                                    }}
                                                                    alt="img"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '200px',
                                                                        objectFit: 'cover',
                                                                        marginTop: '2px',
                                                                        marginBottom: '2px'
                                                                    }}
                                                                />
                                                                <br />
                                                            </span>
                                                        )}
                                                        {message.type === 'document' && message.image && (
                                                            <span
                                                                className="mt-4 mb-4"
                                                                role="button"
                                                                tabIndex={0}
                                                                aria-label="View document"
                                                            >
                                                                {message.image && (
                                                                    <iframe
                                                                        src={`${message.image}#toolbar=0`}
                                                                        style={{
                                                                            border: 'none',
                                                                            width: '100%',
                                                                            height: '200px',
                                                                            marginTop: '2px',
                                                                            marginBottom: '2px'
                                                                        }}
                                                                        title="PDF Document Preview"
                                                                    />
                                                                )}
                                                                <div className="pdf-download-content">
                                                                    <span>
                                                                        <img
                                                                            className="pdf-info"
                                                                            src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                                                                            alt="pdf"
                                                                            style={{ width: '50px', height: '50px' }}
                                                                        />
                                                                        {message.content}
                                                                    </span>
                                                                    <Button
                                                                        onClick={() => window.open(message.image, '_blank')}
                                                                        className="pdf-download-btn"
                                                                    >
                                                                        <CloudDownloadOutlined />
                                                                    </Button>
                                                                </div>
                                                            </span>
                                                        )}
                                                        {message.type === 'audio' && (
                                                            <span>
                                                                <audio controls className="audio-preview">
                                                                    <source src={message.image} type="audio/mpeg" />
                                                                    <source src={message.image} type="audio/ogg" />
                                                                    <source src={message.image} type="audio/wav" />
                                                                    <track kind="captions" src="" label="English captions" />
                                                                    Your browser does not support the audio element.
                                                                </audio>
                                                            </span>
                                                        )}
                                                        <small
                                                            style={{
                                                                color: '#819AFF',
                                                                display: 'flex',
                                                                justifyContent: 'flex-end',
                                                                marginTop: '10px'
                                                            }}
                                                        >
                                                            <small> {dayjs(message.createdAt).format('hh:mm A')}</small>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </Popover>
                                    );

                                    return acc;
                                }, [])}
                        </div>

                        {replyType === 'text' ? (
                            <span>
                                {replyTo.content && (
                                    <div className="replyToMessage">
                                        <p style={{ fontSize: '12px' }}>
                                            <strong className="profile">{replyTo.profile}</strong>
                                            <br /> {replyTo.content}
                                        </p>
                                        <button
                                            className="close-btn"
                                            type="button"
                                            onClick={() => {
                                                setReplyTo({ _id: null, profile: null, content: null });
                                            }}
                                        >
                                            x
                                        </button>
                                    </div>
                                )}
                            </span>
                        ) : null}

                        <div className={`chat-menu-list ${chatMenuVisible ? 'visible' : ''}`}>
                            <div className="chat-menu-item">
                                <span className="chat-menu-item-icon">
                                    <FileImageOutlined />
                                </span>
                                <span className="chat-menu-item-text">Image</span>
                            </div>
                            <div className="chat-menu-item">
                                <span className="chat-menu-item-icon">
                                    <FilePdfOutlined />
                                </span>
                                <span className="chat-menu-item-text">Document</span>
                            </div>
                            <div className="chat-menu-item">
                                <span className="chat-menu-item-icon">
                                    <AudioOutlined />
                                </span>
                                <span className="chat-menu-item-text">Audio</span>
                            </div>
                        </div>

                        <div className={`chat-box-footer ${chatMenuVisible ? 'footer-up' : ''}`}>
                            <input
                                type="text"
                                placeholder="Send message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                            />
                            <button className="ml-5" type="button" onClick={sendMessage}>
                                <SendOutlined />
                            </button>
                            <button
                                className="ml-5"
                                style={
                                    chatMenuVisible
                                        ? {
                                              backgroundColor: '#F6F6F6',
                                              color: '#7B808C'
                                          }
                                        : { backgroundColor: '#4B6EFF' }
                                }
                                type="button"
                                onClick={() => setChatMenuVisible(!chatMenuVisible)}
                            >
                                {chatMenuVisible ? <CloseOutlined /> : <PlusOutlined />}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <Modal open={imageModal} onCancel={() => setImageModal(false)} footer={null} width={600}>
                <div style={{ padding: '20px' }}>
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';
                            }}
                            alt="img"
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '10px',
                                objectFit: 'cover'
                            }}
                        />
                    ) : (
                        <span>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned
                                    files.
                                </p>
                                <div className="d-flex justify-content-center align-items-center mt-4">
                                    {fileList.url && (
                                        <img
                                            src={fileList.url}
                                            onError={(e) => {
                                                e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg';
                                            }}
                                            alt={fileList.name}
                                            className="mr-2"
                                            style={{
                                                width: '100px',
                                                height: '50px',
                                                objectFit: 'contain',
                                                borderRadius: '10px',
                                                border: '1px solid #f0f0f0',
                                                padding: '5px'
                                            }}
                                        />
                                    )}
                                </div>
                            </Dragger>
                        </span>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: 'calc(89% - 20px)',
                            padding: '10px',
                            position: 'absolute',
                            bottom: '0',
                            marginBottom: '10px',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            border: '1px solid #f0f0f0'
                        }}
                    >
                        <input
                            style={{
                                width: 'calc(100% - 50px)',
                                padding: '5px',
                                borderRadius: '5px',
                                border: 'none',
                                outline: 'none',
                                height: '30px'
                            }}
                            type="text"
                            placeholder="Type a message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                        />
                        <button
                            type="button"
                            onClick={() => {
                                sendMessage();
                            }}
                            style={{
                                backgroundColor: '#F6F6F6',
                                color: '#7B808C',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '100px',
                                marginLeft: '10px',
                                cursor: 'pointer',
                                fontSize: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <SendOutlined />
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal open={placeOrder} onCancel={() => setPlaceOrder(false)} footer={null} width={800}>
                <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                    <div className="order-modal-header" style={{ marginBottom: '20px' }}>
                        <span>
                            {tab === 'products' && <h3>Place an order</h3>}
                            {tab === 'place-order' && <h3>Place an order</h3>}
                            {tab === 'customer-details' && <h3>Fill customer details</h3>}
                            {/* {tab === 'order-receipt' && <h3>Order receipt</h3>} */}
                            {tab === 'products' && <p>Search for an item and place order</p>}
                        </span>
                        {tab === 'products' && (
                            <span>
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="Search for Item"
                                    style={{ width: '200px' }}
                                    options={products.map((product) => ({
                                        value: product._id,
                                        label: product.sku
                                    }))}
                                    filterOption={(input, option) => option.label.toLowerCase().startsWith(input.toLowerCase())}
                                    value={product}
                                    onChange={(value) => {
                                        setProduct(value);
                                        console.log('Selected value:', value);
                                        const product = products.find((product) => product._id === value);
                                        if (product) {
                                            console.log('Found product:', product);
                                            setSelectedProducts([...selectedProducts, product]);
                                        } else {
                                            console.log('Product not found for value:', value);
                                        }
                                    }}
                                />
                            </span>
                        )}
                    </div>
                    <div className="order-modal-body table-responsive">
                        {tab === 'products' ? (
                            <Table
                                rowKey={(row) => row._id}
                                dataSource={selectedProducts}
                                columns={[
                                    {
                                        title: 'Product image',
                                        dataIndex: 'partsImage',
                                        key: 'partsImage',
                                        render: (partsImage) => (
                                            <img
                                                src={partsImage}
                                                alt="product"
                                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                            />
                                        )
                                    },
                                    {
                                        title: 'Product name',
                                        dataIndex: 'sku',
                                        key: 'sku'
                                    },
                                    {
                                        title: 'Product price',
                                        dataIndex: 'garagePrice',
                                        key: 'garagePrice'
                                    },

                                    {
                                        title: 'Action',
                                        dataIndex: 'action',
                                        key: 'action',
                                        render: (_, record) => (
                                            <Button
                                                type="primary"
                                                danger
                                                onClick={() => {
                                                    const updatedProducts = selectedProducts.filter((p) => p._id !== record._id);
                                                    setSelectedProducts(updatedProducts);
                                                }}
                                            >
                                                <CloseOutlined />
                                            </Button>
                                        )
                                    }
                                ]}
                            />
                        ) : tab === 'place-order' ? (
                            <div>
                                {selectedProducts.map((product) => (
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px' }}
                                        key={product._id}
                                    >
                                        <div
                                            style={{
                                                width: '140px',
                                                height: '140px',
                                                objectFit: 'cover',
                                                backgroundColor: '#CED4D7',
                                                borderRadius: '10px',
                                                padding: '5px'
                                            }}
                                        >
                                            <img
                                                src={product.partsImage}
                                                alt="product"
                                                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                                            />
                                        </div>
                                        <div>
                                            <p style={{ fontSize: '14px', marginBottom: '2px', paddingBottom: '0px' }}>
                                                <strong> Product name:</strong> {product.sku}
                                            </p>
                                            <p style={{ fontSize: '14px', marginBottom: '2px', paddingBottom: '0px' }}>
                                                <strong> Product price:</strong> {product.garagePrice}
                                            </p>

                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                                                <span>
                                                    <strong>Part type:</strong>{' '}
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder="Select part type"
                                                        options={productType.map((type) => ({
                                                            value: type.name,
                                                            label: type.name
                                                        }))}
                                                        value={product.name}
                                                        onChange={(value) => {
                                                            const updatedProducts = selectedProducts.map((p) =>
                                                                p._id === product._id ? { ...p, partType: value } : p
                                                            );
                                                            setSelectedProducts(updatedProducts);
                                                        }}
                                                        filterOption={(input, option) =>
                                                            option.label.toLowerCase().startsWith(input.toLowerCase())
                                                        }
                                                        style={{ width: '100px', marginLeft: '40px' }}
                                                    />
                                                </span>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                                                <span>
                                                    <strong>Quantity</strong>
                                                </span>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '10px',

                                                        marginLeft: '40px'
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            backgroundColor: '#193B56',
                                                            padding: '5px',
                                                            borderRadius: '5px'
                                                        }}
                                                        onClick={() => {
                                                            const updatedProducts = selectedProducts.map((p) =>
                                                                p._id === product._id ? { ...p, qty: Math.max(0, (p.qty || 0) - 1) } : p
                                                            );
                                                            setSelectedProducts(updatedProducts);
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                const updatedProducts = selectedProducts.map((p) =>
                                                                    p._id === product._id ? { ...p, qty: Math.max(0, (p.qty || 0) - 1) } : p
                                                                );
                                                                setSelectedProducts(updatedProducts);
                                                            }
                                                        }}
                                                    >
                                                        <MinusOutlined style={{ color: '#fff' }} />
                                                    </span>
                                                    <span>{product.qty || 0}</span>
                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            backgroundColor: '#193B56',
                                                            padding: '5px',
                                                            borderRadius: '5px'
                                                        }}
                                                        onClick={() => {
                                                            const updatedProducts = selectedProducts.map((p) =>
                                                                p._id === product._id ? { ...p, qty: (p.qty || 0) + 1 } : p
                                                            );
                                                            setSelectedProducts(updatedProducts);
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.key === ' ') {
                                                                const updatedProducts = selectedProducts.map((p) =>
                                                                    p._id === product._id ? { ...p, qty: (p.qty || 0) + 1 } : p
                                                                );
                                                                setSelectedProducts(updatedProducts);
                                                            }
                                                        }}
                                                    >
                                                        <PlusOutlined style={{ color: '#fff' }} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : tab === 'customer-details' ? (
                            <div>
                                <div style={{ marginBottom: '20px' }}>
                                    <p style={{ fontSize: '14px', marginBottom: '5px' }}>Retailer’s name</p>
                                    <Input
                                        placeholder="Customer name"
                                        style={{ width: '100%', padding: '10px' }}
                                        value={customerName}
                                        onChange={(e) => setCustomerName(e.target.value)}
                                    />
                                </div>
                                <div style={{ marginBottom: '20px' }}>
                                    <p style={{ fontSize: '14px', marginBottom: '5px' }}>Phone number </p>
                                    <Input
                                        placeholder="Phone number"
                                        style={{ width: '100%', padding: '10px' }}
                                        value={customerPhone}
                                        onChange={(e) => setCustomerPhone(e.target.value)}
                                    />
                                </div>
                                <div style={{ marginBottom: '20px' }}>
                                    <p style={{ fontSize: '14px', marginBottom: '5px' }}>Location</p>
                                    <Input
                                        placeholder="Location"
                                        style={{ width: '100%', padding: '10px' }}
                                        value={customerLocation}
                                        onChange={(e) => setCustomerLocation(e.target.value)}
                                    />
                                </div>
                            </div>
                        ) : tab === 'order-receipt' ? (
                            <div className="order-receipt-content">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div className="logo">
                                        <img src={logo} alt="logo" style={{ width: '150px', height: '50px', objectFit: 'contain' }} />
                                    </div>
                                    <div className="order-receipt-header">
                                        <h1 style={{ fontSize: '18px' }}>Garage Mobility LTD</h1>
                                        <a href="mailto:info@garagemobility.com">info@garagemobility.com</a>
                                    </div>
                                </div>
                                <hr />
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: '20px',
                                        marginBottom: '20px'
                                    }}
                                >
                                    <div>
                                        <h1 style={{ fontSize: '14px' }}>INVOICE TO:</h1>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Customer Name:</strong> {customerName}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Phone Number:</strong> {customerPhone}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Location:</strong> {customerLocation}
                                        </p>
                                    </div>
                                    <div>
                                        <h1 style={{ fontSize: '14px' }}>
                                            <strong>Order No:</strong> {orderNo}
                                        </h1>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>DateTime:</strong> {new Date().toLocaleString()}
                                        </p>
                                        <p style={{ fontSize: '14px' }}>
                                            <strong>Relationship Manager:</strong> {localStorage.getItem('userFirstName')}{' '}
                                            {localStorage.getItem('userLastName')}
                                        </p>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table style={{ width: '100%' }}>
                                        <thead style={{ backgroundColor: '#1A3855', color: '#fff', width: '100%' }}>
                                            <tr style={{ fontSize: '14px', fontWeight: 'bold', width: '100%' }}>
                                                <th style={{ borderRadius: '1px' }}>ITEM DESCRIPTION </th>
                                                <th style={{ borderRadius: '1px' }}>QTY</th>
                                                <th style={{ borderRadius: '1px' }}>PART TYPE</th>
                                                <th style={{ borderRadius: '1px' }}>AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: '14px' }}>
                                            {selectedProducts.map((product) => (
                                                <tr key={product._id}>
                                                    <td>{product.sku}</td>
                                                    <td>{product.qty}</td>
                                                    <td>{product.partType}</td>
                                                    <td>{product.garagePrice}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr style={{ marginTop: '20px' }}>
                                                <td colSpan={3} style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    Total Payable:
                                                </td>
                                                <td style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                                    {selectedProducts.reduce(
                                                        (total, product) => total + product.garagePrice * product.qty,
                                                        0
                                                    )}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className="order-modal-footer" style={{ marginTop: '20px' }}>
                        {selectedProducts.length > 0 && (
                            <>
                                {tab === 'products' && (
                                    <Button className="order-modal-footer-btn" onClick={() => setTab('place-order')}>
                                        Continue
                                    </Button>
                                )}
                                {tab === 'place-order' && (
                                    <Button
                                        disabled={selectedProducts.some((product) => !product.qty || !product.partType)}
                                        className="order-modal-footer-btn"
                                        onClick={() => setTab('customer-details')}
                                    >
                                        Continue
                                    </Button>
                                )}
                                {tab === 'customer-details' && (
                                    <Button
                                        disabled={!customerName || !customerPhone || !customerLocation}
                                        loading={placeOrderLoading}
                                        className="order-modal-footer-btn"
                                        onClick={() => placeOrderFunction()}
                                    >
                                        Place order
                                    </Button>
                                )}
                                {tab === 'order-receipt' && (
                                    <Button
                                        className="order-modal-footer-btn"
                                        onClick={async () => {
                                            try {
                                                setReceiptLoading(true);
                                                // Import html2pdf
                                                const html2pdf = (await import('html2pdf.js')).default;

                                                // Get the content to be converted to PDF
                                                const content = document.querySelector('.order-receipt-content');

                                                // Generate PDF using html2pdf
                                                const opt = {
                                                    margin: 1,
                                                    filename: 'order_receipt.pdf',
                                                    image: { type: 'jpeg', quality: 0.98 },
                                                    html2canvas: { scale: 2, useCORS: true },
                                                    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                                                };

                                                // Generate PDF and get blob
                                                const pdfBlob = await new Promise((resolve, reject) => {
                                                    html2pdf().set(opt).from(content).toPdf().outputPdf('blob').then(resolve).catch(reject);
                                                });

                                                // Create a File object from the PDF blob
                                                const pdfFile = new File([pdfBlob], 'order_receipt.pdf', { type: 'application/pdf' });

                                                // Create an object with the required properties
                                                const fileObject = new File([pdfBlob], 'order_receipt.pdf', {
                                                    type: 'application/pdf',
                                                    lastModified: Date.now()
                                                });
                                                fileObject.uid = `rc-upload-${Date.now()}`;
                                                fileObject.originalname = 'order_receipt.pdf';
                                                // Upload the PDF file
                                                const response = await imageUploadRequest(fileObject);
                                                console.log(response, 'response');
                                                setReceiptLoading(false);
                                                if (response && response.status === 'done') {
                                                    // Set the message and message type
                                                    const orderReceiptMessage = 'Order receipt sent';
                                                    const orderReceiptType = 'document';
                                                    const imageUrl = response.url;

                                                    const newMessage = {
                                                        phone: selectedUser.phone,
                                                        content: {
                                                            body: orderReceiptMessage,
                                                            type: orderReceiptType, // 'text' or 'image',
                                                            url:
                                                                orderReceiptType === 'image' || orderReceiptType === 'document'
                                                                    ? imageUrl
                                                                    : ''
                                                        },
                                                        user_id: localStorage.getItem('userId'),
                                                        replyTo: replyTo.whatsapp_id
                                                    };

                                                    socket.emit('mira_chat', newMessage);

                                                    // Transform message content
                                                    const transformedMessage = {
                                                        phone: selectedUser.phone,
                                                        content: orderReceiptMessage,
                                                        type: orderReceiptType,
                                                        isSent: 'mira' || 'staff',
                                                        agent: 'staff',
                                                        image:
                                                            orderReceiptType === 'image' || orderReceiptType === 'document' ? imageUrl : '',
                                                        user: `${localStorage.getItem('userFirstName')} ${localStorage.getItem(
                                                            'userLastName'
                                                        )}`,
                                                        _id: Math.random().toString(36).substring(2, 15),
                                                        createdAt: new Date().toISOString()
                                                    };

                                                    setMessages((prevMessages) => [...prevMessages, transformedMessage]);

                                                    setMessage('');
                                                    setMessageType('text');
                                                    setImageUrl('');
                                                    setPlaceOrder(false);
                                                    setTab('products');
                                                    setSelectedProducts([]);
                                                    setCustomerName('');
                                                    setCustomerPhone('');
                                                    setCustomerLocation('');
                                                }
                                            } catch (error) {
                                                toast.error('Failed to send order receipt');
                                                setReceiptLoading(false);
                                            }
                                        }}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        loading={receiptLoading}
                                    >
                                        <img
                                            src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                                            alt="PDF icon"
                                            height={20}
                                            style={{ marginRight: '5px' }}
                                        />
                                        {receiptLoading ? 'Sending...' : 'Send order receipt'}
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Index;
