import { useState, useEffect } from 'react';
import { Button, Input, message, Upload, Tag } from 'antd';
import { CheckCircleOutlined, InboxOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import ApiUrl from '../../../network';
import { decrypt } from '../../../utils/encrypt';
import { toast } from 'react-toastify';
import { useParams, Link } from 'react-router-dom';
import normFile from '../../../imageToBucket';

const { Dragger } = Upload;

const keyResult = localStorage.getItem('key');
const ivResult = localStorage.getItem('iv');

const country = localStorage.getItem('country');

export const Index = () => {
    const { id } = useParams();
    const [retailerDetails, setRetailerDetails] = useState({
        firstName: '',
        lastName: '',
        dataCountry: localStorage.getItem('country'),
        shopData: {
            shopname: '',
            shoplocation: '',
            shoplat: '',
            shoplng: '',
            street: '',
            neighbourhood: '',
            city: '',
            state: '',
            zip: '',
            addressDescription: ''
        },
        stage: '2',
        shopPhoto: '',
        nationalId: '',
        notes: ''
    });
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [verifyStage, setVerifyStage] = useState('1');
    const styles = {
        header: {
            backgroundColor: '#0A2938',
            padding: 20,
            borderRadius: 10
        },
        headerContent: {
            fontFamily: 'Poppins',
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '38px',
            textAlign: 'left',
            color: '#0A2938'
        },
        contentHeaderParagraph: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'left',
            color: '#0A2938'
        },
        content: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filters: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#696E7C'
        },
        filterButton: {
            width: '140px',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none',
            marginRight: '10px'
        },
        resetButton: {
            width: 'fit-content',
            borderRadius: '8px',
            backgroundColor: '#fff',
            color: '#000',
            border: '1px solid #D9DBDE'
        },
        table: {
            borderRadius: '16px',
            backgroundColor: '#fff',
            border: '1px solid #E5E5E5'
        },
        actionButton: {
            backgroundColor: 'transparent',
            color: '#FF740F',
            border: '1px solid #FF740F',
            borderRadius: '8px',
            fontFamily: 'Poppins',
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 500,
            width: 'fit-content'
        },
        modalHeader: {
            fontFamily: 'Poppins',
            fontSize: 24,
            fontWeight: 500,
            lineHeight: '32px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        modalParagraph: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
            textAlign: 'left',
            color: '#E7EAEB'
        },
        saveButton: {
            width: '150px',
            borderRadius: '8px',
            backgroundColor: '#FF740F',
            color: '#fff',
            border: 'none'
        },
        modalHeaderContainer: {
            backgroundColor: '#233E4C',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#E7EAEB',
            padding: 20,
            borderRadius: 10
        },
        modalBody: {
            backgroundColor: '#fff',
            padding: 40,
            borderRadius: 10,
            width: '60%'
        }
    };

    // fetch retailers
    const fetchRetailer = async () => {
        try {
            setLoading(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/get-channel-partner?retailer_id=${id}&country=${country}`, config);
            const data = await response.json();
            if (data.status === true) {
                const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                setVerifyStage(decryptedData?.verifyStage);

                setRetailerDetails({
                    firstName: decryptedData?.firstName,
                    lastName: decryptedData?.lastName,
                    dataCountry: decryptedData?.dataCountry,
                    shopData: {
                        shopname: decryptedData?.shopsdata[0]?.shopname,
                        shoplocation: decryptedData?.shopsdata[0]?.shoplocation,
                        shoplat: decryptedData?.shopsdata[0]?.shoplat,
                        shoplng: decryptedData?.shopsdata[0]?.shoplng,
                        street: decryptedData?.shopsdata[0]?.street,
                        neighbourhood: decryptedData?.shopsdata[0]?.neighbourhood,
                        city: decryptedData?.shopsdata[0]?.city,
                        state: decryptedData?.shopsdata[0]?.state,
                        zip: decryptedData?.shopsdata[0]?.zip,
                        addressDescription: decryptedData?.shopsdata[0]?.addressDescription
                    },
                    stage: '2',
                    shopPhoto: decryptedData?.shopsdata[0]?.photo,
                    nationalId: decryptedData?.idCardAttached,
                    notes: decryptedData?.notes
                });
                setLoading(false);
            } else {
                setLoading(false);
                toast.error(data.message);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const updateRetailer = async () => {
        try {
            setLoadingUpdate(true);
            const userInfo = localStorage.getItem('userToken');
            const config = {
                headers: {
                    'auth-token': userInfo,
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(`${ApiUrl.BaseUrl}/crm/update-channel-partner/${id}`, {
                method: 'PATCH',
                body: JSON.stringify(retailerDetails),
                headers: config.headers
            });
            const data = await response.json();

            if (data.status === true || data.message === '') {
                const decryptedData = JSON.parse(decrypt(data.data, keyResult, ivResult));
                toast.success(decryptedData);
                setDisabled(true);
                fetchRetailer();
            } else {
                toast.error(data.message);
            }
            setLoadingUpdate(false);
        } catch (error) {
            toast.error('Failed to update retailer');
            setLoadingUpdate(false);
        }
    };

    useEffect(() => {
        fetchRetailer();
    }, []);

    const idUploadRequest = async (file, type) => {
        try {
            const token = localStorage.getItem('userToken');
            const rootURL = ApiUrl.BaseUrl;
            const compressedImage = await normFile(file, token, rootURL);

            if (compressedImage) {
                if (type === 'photo') {
                    setRetailerDetails({ ...retailerDetails, shopPhoto: compressedImage });
                } else {
                    setRetailerDetails({ ...retailerDetails, nationalId: compressedImage });
                }
                toast.success(type === 'photo' ? 'Photo uploaded successfully' : 'National ID uploaded successfully');

                return {
                    status: 'done',
                    response: compressedImage
                };
            }
            // Return a default value if compressedImage is falsy
            return {
                status: 'error',
                response: 'Failed to compress image'
            };
        } catch (error) {
            return {
                status: 'error'
            };
        }
    };

    const props = {
        name: 'file',
        multiple: true,
        customRequest: ({ file, onSuccess, onError }) => {
            idUploadRequest(file, 'photo')
                .then((response) => {
                    if (response.status === 'done') {
                        onSuccess(response);
                    } else {
                        onError(response);
                    }
                })
                .catch((error) => {
                    onError(error);
                });
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        }
    };

    const idProps = {
        name: 'file',
        multiple: false,
        customRequest: ({ file, onSuccess, onError }) => {
            idUploadRequest(file, 'id')
                .then((response) => {
                    if (response.status === 'done') {
                        onSuccess(response);
                    } else {
                        onError(response);
                    }
                })
                .catch((error) => {
                    onError(error);
                });
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        }
    };

    return (
        <div className="verification">
            <div className="header" style={{ marginBottom: 0 }}>
                <div className="header-content">
                    <Link to="/verification/second-stage" style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
                        <ArrowLeftOutlined style={{ color: '#000', fontSize: 26 }} />
                        <div>
                            <h1 style={styles.headerContent}>In-House Verification</h1>
                        </div>
                    </Link>
                    <p style={styles.contentHeaderParagraph}>view all field verified retailer information</p>
                </div>
                {verifyStage !== '1' ? (
                    <div
                        style={{
                            backgroundColor: 'transparent',
                            padding: '10px 20px',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <svg width="83" height="83" viewBox="0 0 183 230" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.0348 43.0978L25.1957 73.5978L18.0348 103.965L44.6891 120.409L61 147.063L91.5 139.902L122 147.063L138.311 120.409L164.965 103.965L157.804 73.5978L164.965 43.0978L138.444 26.6543L122 0L91.5 7.29348L61.1326 0.132609L44.5565 26.6543L18.0348 43.0978ZM91.6326 127.172C77.5646 127.172 64.0728 121.583 54.1252 111.636C44.1776 101.688 38.5891 88.1963 38.5891 74.1283C38.5891 60.0603 44.1776 46.5684 54.1252 36.6209C64.0728 26.6733 77.5646 21.0848 91.6326 21.0848C120.807 21.0848 144.543 44.8217 144.543 73.9956C144.543 103.435 120.807 127.172 91.6326 127.172ZM91.3674 113.911C69.3544 113.911 51.7174 96.1413 51.7174 74.1283C51.7174 52.2478 69.3544 34.3457 91.3674 34.3457C113.38 34.3457 131.283 52.2478 131.283 74.1283C131.283 96.1413 113.38 113.911 91.3674 113.911ZM142.289 128.498L125.315 158.202L97.7326 151.97L131.283 229.28L149.848 200.107H183L142.289 128.498ZM40.1804 129.426L56.7565 159.263L85.0022 152.5L68.3598 190.89L51.7174 229.28L33.1522 200.107H0L40.1804 129.426Z"
                                fill="#F69426"
                            />
                            <path
                                d="M0 200.117L39.7826 128.508L57.0217 159.008L86.1957 151.051L51.7174 229.29L33.1522 200.117H0Z"
                                fill="#1AB366"
                            />
                            <path
                                d="M183 200.114L141.892 127.18L124.653 157.68L96.8047 151.049L131.283 229.288L149.848 200.114H183Z"
                                fill="#1AB366"
                            />
                        </svg>
                        <Tag color="green">Verification Successful!</Tag>
                    </div>
                ) : (
                    <Button
                        loading={loadingUpdate}
                        disabled={
                            !retailerDetails.firstName ||
                            !retailerDetails.lastName ||
                            !retailerDetails.shopData.shopname ||
                            !retailerDetails.shopData.shoplocation ||
                            !retailerDetails.shopData.shoplat ||
                            !retailerDetails.shopData.shoplng ||
                            !retailerDetails.shopData.street ||
                            !retailerDetails.shopData.neighbourhood ||
                            !retailerDetails.shopData.city
                        }
                        onClick={() => {
                            if (disabled) {
                                setDisabled(false);
                            } else {
                                setDisabled(true);
                            }
                        }}
                        style={styles.saveButton}
                    >
                        {disabled ? 'Edit' : 'Cancel'}
                    </Button>
                )}
            </div>

            <div style={{ backgroundColor: '#fff', padding: 20, borderRadius: 10 }}>
                <div className="modal-body" style={styles.modalBody}>
                    <h3
                        style={{
                            marginBottom: '16px',
                            fontSize: '20px',
                            lineHeight: '30px',
                            fontWeight: 400,
                            fontFamily: 'Poppins'
                        }}
                    >
                        Verify retailer information
                    </h3>
                    <div className="modal-body-content" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                        <span style={{ width: '100%', marginRight: '16px' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.firstName ? '#00AA55' : '#696E7C',
                                        backgroundColor: retailerDetails?.firstName ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                First Name
                            </p>
                            <Input
                                value={retailerDetails.firstName}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        firstName: e.target.value
                                    })
                                }
                                placeholder="Retailer First Name"
                                style={{ width: '100%', border: 'none', outline: 'none', padding: '10px', backgroundColor: '#F7F8F8' }}
                                disabled={disabled}
                            />
                        </span>
                        <span style={{ width: '100%' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.lastName ? '#00AA55' : '#696E7C',
                                        backgroundColor: retailerDetails?.lastName ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                Last Name
                            </p>
                            <Input
                                value={retailerDetails?.lastName}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        lastName: e.target.value
                                    })
                                }
                                placeholder="Retailer Last Name"
                                style={{ width: '100%', border: 'none', outline: 'none', padding: '10px', backgroundColor: '#F7F8F8' }}
                                disabled={disabled}
                            />
                        </span>
                    </div>
                    <h3
                        style={{
                            marginBottom: '16px',
                            fontSize: '20px',
                            lineHeight: '30px',
                            fontWeight: 400,
                            fontFamily: 'Poppins'
                        }}
                    >
                        Verify Location
                    </h3>
                    <div className="modal-body-content" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                        <span style={{ width: '100%', marginRight: '16px' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.shopData?.shopname ? '#00AA55' : '#696E7C',
                                        backgroundColor: retailerDetails?.shopData?.shopname ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                Shop name
                            </p>
                            <Input
                                value={retailerDetails?.shopData?.shopname}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        shopData: { ...retailerDetails.shopData, shopname: e.target.value }
                                    })
                                }
                                placeholder="Shop name"
                                style={{ width: '100%', border: 'none', outline: 'none', padding: '10px', backgroundColor: '#F7F8F8' }}
                                disabled={disabled}
                            />
                        </span>
                        <span style={{ width: '100%' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.shopData?.shoplocation ? '#00AA55' : '#696E7C',
                                        backgroundColor: retailerDetails?.shopData?.shoplocation ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                Address
                            </p>
                            <Input
                                value={retailerDetails?.shopData?.shoplocation}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        shopData: { ...retailerDetails.shopData, shoplocation: e.target.value }
                                    })
                                }
                                placeholder="Address"
                                style={{ width: '100%', border: 'none', outline: 'none', padding: '10px', backgroundColor: '#F7F8F8' }}
                                disabled={disabled}
                            />
                        </span>
                    </div>
                    <div className="modal-body-content" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
                        <span style={{ width: '100%', marginRight: '16px' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.shopData?.shoplat ? '#00AA55' : '#696E7C',
                                        backgroundColor: retailerDetails?.shopData?.shoplat ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                Latitude
                            </p>
                            <Input
                                value={retailerDetails?.shopData?.shoplat}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        shopData: { ...retailerDetails.shopData, shoplat: e.target.value }
                                    })
                                }
                                placeholder="Latitude"
                                style={{ width: '100%', border: 'none', outline: 'none', padding: '10px', backgroundColor: '#F7F8F8' }}
                                disabled
                            />
                        </span>
                        <span style={{ width: '100%' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.shopData?.shoplng ? '#00AA55' : '#696E7C',
                                        backgroundColor: retailerDetails?.shopData?.shoplng ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                Longitude
                            </p>
                            <Input
                                value={retailerDetails?.shopData?.shoplng}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        shopData: { ...retailerDetails.shopData, shoplng: e.target.value }
                                    })
                                }
                                placeholder="34°S, 150°E"
                                style={{ width: '100%', border: 'none', outline: 'none', padding: '10px', backgroundColor: '#F7F8F8' }}
                                disabled
                            />
                        </span>
                    </div>
                    <div className="modal-body-content" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <span style={{ width: '100%', marginRight: '16px' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.shopData?.street ? '#00AA55' : '#696E7C',
                                        backgroundColor: retailerDetails?.shopData?.street ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                Street
                            </p>
                            <Input
                                value={retailerDetails?.shopData?.street}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        shopData: { ...retailerDetails.shopData, street: e.target.value }
                                    })
                                }
                                placeholder="Street"
                                style={{ width: '100%', border: 'none', outline: 'none', padding: '10px', backgroundColor: '#F7F8F8' }}
                                disabled={disabled}
                            />
                        </span>
                        <span style={{ width: '100%' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.shopData?.neighbourhood ? '#00AA55' : '#696E7C',
                                        backgroundColor: retailerDetails?.shopData?.neighbourhood ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                Neighborhood
                            </p>
                            <Input
                                value={retailerDetails?.shopData?.neighbourhood}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        shopData: { ...retailerDetails.shopData, neighbourhood: e.target.value }
                                    })
                                }
                                placeholder="Neighborhood"
                                style={{ width: '100%', border: 'none', outline: 'none', padding: '10px', backgroundColor: '#F7F8F8' }}
                                disabled={disabled}
                            />
                        </span>
                    </div>
                    <div className="modal-body-content" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <span style={{ width: '100%', marginRight: '16px' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '18px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.shopData?.city ? '#00AA55' : '#696E7C',
                                        backgroundColor: retailerDetails?.shopData?.city ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                City
                            </p>
                            <Input
                                value={retailerDetails?.shopData?.city}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        shopData: { ...retailerDetails.shopData, city: e.target.value }
                                    })
                                }
                                placeholder="City"
                                style={{ width: '100%', border: 'none', outline: 'none', padding: '10px', backgroundColor: '#F7F8F8' }}
                                disabled={disabled}
                            />
                        </span>
                        <span style={{ width: '100%' }}>
                            <p style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px' }}>Additional description</p>
                            <Input.TextArea
                                value={retailerDetails?.shopData?.addressDescription}
                                onChange={(e) =>
                                    setRetailerDetails({
                                        ...retailerDetails,
                                        shopData: { ...retailerDetails.shopData, addressDescription: e.target.value }
                                    })
                                }
                                placeholder="Additional description"
                                style={{
                                    width: '100%',
                                    border: 'none',
                                    outline: 'none',
                                    padding: '10px',
                                    backgroundColor: '#F7F8F8',
                                    resize: 'none'
                                }}
                                disabled={disabled}
                            />
                        </span>
                    </div>
                    <h3
                        style={{
                            marginBottom: '16px',
                            fontSize: '20px',
                            lineHeight: '30px',
                            fontWeight: 400,
                            fontFamily: 'Poppins'
                        }}
                    >
                        Retailer files
                    </h3>
                    <div>
                        <div style={{ width: '100%', marginBottom: '20px' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '16px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.shopPhoto ? '#00AA55' : '#000',
                                        backgroundColor: retailerDetails?.shopPhoto ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                {retailerDetails?.shopPhoto ? 'Upload Photo of shop' : 'Upload Photo of shop'}
                            </p>
                            <Dragger {...props} disabled={disabled}>
                                <p className="ant-upload-drag-icon">
                                    {retailerDetails?.shopPhoto ? (
                                        <img
                                            src={retailerDetails?.shopPhoto}
                                            alt="shop"
                                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                        />
                                    ) : (
                                        <InboxOutlined />
                                    )}
                                </p>
                                <p className="ant-upload-text">Click to upload</p>
                                <p className="ant-upload-hint">Maximum file size is 10mb</p>
                            </Dragger>
                        </div>
                        <div style={{ width: '100%', marginBottom: '20px' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '16px' }}>
                                <CheckCircleOutlined
                                    style={{
                                        color: retailerDetails?.nationalId ? '#00AA55' : '#000',
                                        backgroundColor: retailerDetails?.nationalId ? '#E6F7EE' : '#D9D9D9',
                                        borderRadius: '50%',
                                        padding: '4px'
                                    }}
                                />
                                {retailerDetails?.nationalId ? 'Upload National ID (Optional)' : 'Upload National ID'}
                            </p>
                            <Dragger {...idProps} disabled={disabled}>
                                <p className="ant-upload-drag-icon">
                                    {retailerDetails?.nationalId ? (
                                        <img
                                            src={retailerDetails?.nationalId}
                                            alt="nationalId"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    ) : (
                                        <InboxOutlined />
                                    )}
                                </p>
                                <p className="ant-upload-text">Click to upload</p>
                                <p className="ant-upload-hint">Maximum file size is 10mb</p>
                            </Dragger>
                        </div>

                        <div style={{ width: '100%', marginBottom: '20px' }}>
                            <p style={{ display: 'flex', gap: '6px', fontSize: '16px' }}>
                                <span style={{ fontWeight: 400, color: '#4B6EFF' }}> Add Comments</span> (Optional)
                            </p>
                            <Input.TextArea
                                value={retailerDetails.notes}
                                onChange={(e) => setRetailerDetails({ ...retailerDetails, notes: e.target.value })}
                                placeholder="Add Comments"
                                style={{ width: '100%', height: '100px', resize: 'none' }}
                                disabled={disabled}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <Button
                                onClick={updateRetailer}
                                style={{
                                    width: '100%',
                                    backgroundColor: '#FF740F',
                                    color: '#fff',
                                    borderRadius: '8px',
                                    border: 'none',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    fontWeight: 500,
                                    fontFamily: 'Poppins'
                                }}
                                disabled={
                                    !retailerDetails.firstName ||
                                    !retailerDetails.lastName ||
                                    !retailerDetails.shopData.shopname ||
                                    !retailerDetails.shopData.shoplocation ||
                                    !retailerDetails.shopData.shoplat ||
                                    !retailerDetails.shopData.shoplng ||
                                    !retailerDetails.shopData.street ||
                                    !retailerDetails.shopData.neighbourhood ||
                                    !retailerDetails.shopData.city ||
                                    !retailerDetails.shopData.addressDescription ||
                                    disabled
                                }
                                loading={loadingUpdate}
                            >
                                Complete Verification
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
