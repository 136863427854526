// assets
import { IconBike, IconBrandGoogleAnalytics, IconCarCrane, IconFileInvoice, IconListSearch, IconShadow } from '@tabler/icons';

import ArchitectureIcon from '@mui/icons-material/Architecture';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import BoltIcon from '@mui/icons-material/Bolt';
import BugReportIcon from '@mui/icons-material/BugReport';
import CarCrashIcon from '@mui/icons-material/CarRental';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import CollectionsIcon from '@mui/icons-material/Collections';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { FaBookReader } from 'react-icons/fa';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { MdLoyalty } from 'react-icons/md';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ReportIcon from '@mui/icons-material/Report';
// import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import Scale from '@mui/icons-material/Scale';
import SendIcon from '@mui/icons-material/Send';
import Shop from '@mui/icons-material/Shop2';
// import { SiSimpleanalytics } from 'react-icons/si';
import Trans from '@mui/icons-material/Money';
import vinSearch from '@mui/icons-material/Search';
import MapIcon from '@mui/icons-material/Map';
import GroupsIcon from '@mui/icons-material/Groups';
import PaymentsIcon from '@mui/icons-material/Payments';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Man from '@mui/icons-material/Man';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddchartIcon from '@mui/icons-material/Addchart';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import CategoryIcon from '@mui/icons-material/Category';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import GarageIcon from '@mui/icons-material/Garage';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import InsightsIcon from '@mui/icons-material/Insights';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import TimelineIcon from '@mui/icons-material/Timeline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';

// new icons
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import WifiFindIcon from '@mui/icons-material/WifiFind';
import GradingIcon from '@mui/icons-material/Grading';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CachedIcon from '@mui/icons-material/Cached';
import GroupIcon from '@mui/icons-material/Group';
import ReplayIcon from '@mui/icons-material/Replay';
import BuildIcon from '@mui/icons-material/Build';
import BarChartIcon from '@mui/icons-material/BarChart';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import HotelIcon from '@mui/icons-material/Hotel';

// constant
const icons = {
    ListAltIcon,
    BoltIcon,
    AutoAwesomeIcon,
    ArchitectureIcon,
    IconFileInvoice,
    IconCarCrane,
    IconShadow,
    IconBrandGoogleAnalytics,
    IconBike,
    IconListSearch,
    DashboardIcon,
    InventoryIcon,
    ReportIcon,
    CarRepairIcon,
    CarCrashIcon,
    Scale,
    Trans,
    Shop,
    vinSearch,
    QueryStatsIcon,
    InsertChartIcon,
    CollectionsIcon,
    BugReportIcon,
    InsertPhotoIcon,
    SendIcon,
    MapIcon,
    GroupsIcon,
    PaymentsIcon,
    HowToRegIcon,
    Man,
    FactCheckIcon,
    LeaderboardIcon,
    DragIndicatorIcon,
    AddchartIcon,
    TravelExploreIcon,
    ViewModuleIcon,
    EmojiTransportationIcon,
    CategoryIcon,
    LocalShippingIcon,
    DepartureBoardIcon,
    NoCrashIcon,
    GarageIcon,
    StackedBarChartIcon,
    InsightsIcon,
    ReceiptLongIcon,
    AutoStoriesIcon,
    TimelineIcon,
    BorderColorIcon,
    AnalyticsIcon,
    DataThresholdingIcon,
    AddLocationIcon,
    ViewInArIcon,
    AttachMoneyIcon,
    ContentPasteIcon,
    WifiFindIcon,
    GradingIcon,
    SpeakerNotesIcon,
    SupportAgentIcon,
    FormatListBulletedIcon,
    ManageHistoryIcon,
    HandshakeIcon,
    CachedIcon,
    GroupIcon,
    ReplayIcon,
    BuildIcon,
    BarChartIcon,
    MapRoundedIcon,
    HotelIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //
const userRole = localStorage.getItem('userRole');
const utilities = {
    id: 'utilities',
    title: '',
    type: userRole,
    children: [
        {
            id: 'home',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'ct',
            title: 'Control center',
            type: 'collapse',
            icon: icons.AddLocationIcon,
            children: [
                {
                    id: 'controlcenter',
                    title: 'Live order maps',
                    type: 'item',
                    url: '/map',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'salesOperations',
            title: 'Sales Operations',
            type: 'collapse',
            icon: icons.AttachMoneyIcon,
            children: [
                {
                    id: 'routeschedule',
                    title: 'Route Schedule',
                    type: 'item',
                    url: '/admin-route-schedule'
                },
                {
                    id: 'orders',
                    title: 'Orders',
                    type: 'collapse',
                    children: [
                        {
                            id: 'orders',
                            title: 'Orders',
                            type: 'item',
                            url: '/order',
                            breadcrumbs: false
                        },
                        {
                            id: 'proformacssorders',
                            title: 'Proforma Orders',
                            type: 'item',
                            url: '/proformacssorders',
                            breadcrumbs: false
                        },
                        {
                            id: 'presoldorders',
                            title: 'PreSold Orders',
                            type: 'item',
                            url: '/presoldorders',
                            breadcrumbs: false
                        },
                        // {
                        //     id: 'darkstores',
                        //     title: 'Dark Stores Orders',
                        //     type: 'item',
                        //     url: '/darkstores',
                        //     icon: icons.Trans,
                        //     breadcrumbs: false
                        // },
                        {
                            id: 'csssalesdriveorders',
                            title: 'Sales Drive Orders',
                            type: 'item',
                            url: '/csssalesdriveorders'
                        },
                        {
                            id: 'retentionorders',
                            title: 'Retention Drive Orders',
                            type: 'item',
                            url: '/retentionorders',
                            breadcrumbs: false
                        },

                        {
                            id: 'conversionorders',
                            title: 'Conversion Drive Orders',
                            type: 'item',
                            url: '/conversionorders',
                            breadcrumbs: false
                        },
                        {
                            id: 'adminorderrequestform',
                            title: 'Place Order',
                            type: 'item',
                            url: '/adminorderrequestform',
                            breadcrumbs: false
                        },
                        {
                            id: 'icons',
                            title: 'Order Issues',
                            type: 'collapse',
                            icon: icons.BugReportIcon,
                            children: [
                                {
                                    id: 'orderissues',
                                    title: 'Order Issues',
                                    type: 'item',
                                    url: '/orderissues',
                                    breadcrumbs: false
                                },
                                {
                                    id: 'resolvedIssue',
                                    title: 'Resolved Order Issues',
                                    type: 'item',
                                    url: '/resolvedIssue',
                                    breadcrumbs: false
                                }
                            ]
                        }
                    ]
                },
                {
                    id: 'kpi',
                    title: 'KPI Plannng',
                    type: 'collapse',
                    children: [
                        {
                            id: 'kpiplanning',
                            title: 'KPI Planning',
                            type: 'item',
                            url: '/kpi',
                            breadcrumbs: false
                        },
                        {
                            id: 'addkpi',
                            title: 'Add KPI',
                            type: 'item',
                            url: '/kpi/set-kpi',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'salesplanning',
                    title: 'Drive Planning',
                    type: 'collapse',
                    children: [
                        {
                            id: 'salesplanning',
                            title: 'Plan drives',
                            type: 'item',
                            url: '/salesplanning',
                            breadcrumbs: false
                        },
                        {
                            id: 'driveType',
                            title: 'Add drive type',
                            type: 'item',
                            url: '/salesplan/drivetype',
                            breadcrumbs: false
                        },
                        {
                            id: 'saleslocation',
                            title: 'Add drive location',
                            type: 'item',
                            url: '/salesplan/location',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'parts-catalog',
                    title: 'Parts Catalog',
                    type: 'item',
                    url: '/parts-catalog',
                    breadcrumbs: false
                },
                {
                    id: 'part-discount',
                    title: 'Check Part Discount',
                    type: 'item',
                    url: '/part-discount',
                    breadcrumbs: false
                },
                {
                    id: 'pricing',
                    title: 'Pricing',
                    type: 'collapse',
                    children: [
                        {
                            id: 'dashboard',
                            title: 'Dashboard',
                            type: 'item',
                            url: '/pricing-dashboard',
                            breadcrumbs: false
                        },
                        {
                            id: 'product-prices',
                            title: 'Product prices',
                            type: 'item',
                            url: '/pricing-parts',
                            breadcrumbs: false
                        },
                        {
                            id: 'banner-settings',
                            title: 'Banner settings',
                            type: 'item',
                            url: '/pricing-banner-setups'
                        }
                    ]
                }
            ]
        },
        {
            id: 'finance-operations',
            title: 'Finance operations',
            type: 'collapse',
            icon: icons.PaymentsIcon,
            children: [
                {
                    id: 'momocollections',
                    title: 'MoMo Collections',
                    type: 'item',
                    url: '/allmomocollections',
                    breadcrumbs: false
                },
                // {
                //     id: 'collection orders',
                //     title: 'Collections',
                //     type: 'item',
                //     url: '/collectionorders',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'depositedorderlist',
                //     title: 'Deposit',
                //     type: 'item',
                //     url: '/depositedorderlist',
                //     breadcrumbs: false
                // },
                {
                    id: 'clearancesheet',
                    title: 'Clearance Sheet',
                    type: 'item',
                    url: '/clearancesheet',
                    breadcrumbs: false
                },
                {
                    id: 'epl',
                    title: 'Easy Pay Later',
                    type: 'collapse',
                    children: [
                        {
                            id: 'configuration',
                            title: 'EPL configuration',
                            type: 'item',
                            url: '/epl/configuration'
                        },
                        {
                            id: 'tracker',
                            title: 'EPL Tracker/Payment',
                            type: 'item',
                            url: '/epl/easy-pay-later'
                        },
                        {
                            id: 'summary',
                            title: 'EPL summary',
                            type: 'item',
                            url: '/epl/easy-pay-summary'
                        },
                        {
                            id: 'eplrequest',
                            title: 'EPL Request',
                            type: 'item',
                            url: '/epl/easy-pay-request'
                        },
                        {
                            id: 'creditstatus',
                            title: 'Credit Status',
                            type: 'item',
                            url: '/epl/credit_status'
                        }
                    ]
                },
                {
                    id: 'payment-list',
                    title: 'Order Payments',
                    type: 'item',
                    url: '/payment-transactions'
                }
            ]
        },
        {
            id: 'Supply-chain-operation',
            title: 'Supply Chain Operation',
            type: 'collapse',
            icon: icons.ContentPasteIcon,
            children: [
                // {
                //     id: 'ordertablestatus',
                //     title: 'Update order status',
                //     type: 'item',
                //     url: '/ordertablestatus',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'batchespage',
                //     title: 'Batch Orders',
                //     type: 'item',
                //     url: '/batchespage',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'orderpriority',
                //     title: 'Order Priority',
                //     type: 'item',
                //     url: '/order-priority',
                //     breadcrumbs: false
                // },
                {
                    id: 'fulfillment',
                    title: 'Delivery Notes',
                    type: 'collapse',
                    children: [
                        {
                            id: 'deliverynotes',
                            title: 'View notes',
                            type: 'item',
                            url: '/orders/deliveries/notes',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'orderswaybill',
                    title: 'Waybill',
                    type: 'collapse',
                    children: [
                        {
                            id: 'orders',
                            title: 'Batch order',
                            type: 'item',
                            url: '/waybill/orders',
                            breadcrumbs: false
                        },
                        {
                            id: 'batch',
                            title: 'Generate waybill',
                            url: '/waybill/batch',
                            type: 'item',
                            breadcrumbs: false
                        },
                        {
                            id: 'waybill',
                            title: 'View waybill',
                            type: 'item',
                            url: '/generated/waybill',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'icons2',
                    title: 'Delivery Images',
                    type: 'collapse',
                    children: [
                        {
                            id: 'deliverynoteimages',
                            title: 'Delivery Notes Images',
                            type: 'item',
                            url: '/deliverynoteimages',
                            breadcrumbs: false
                        },
                        {
                            id: 'dispatchimages',
                            title: 'Dispatch Images',
                            type: 'item',
                            url: '/dispatchimages',
                            breadcrumbs: false
                        },
                        {
                            id: 'packsortimages',
                            title: 'Pack Sort Images',
                            type: 'item',
                            url: '/packsortimages',
                            breadcrumbs: false
                        },
                        {
                            id: 'alldriverdeliveryimages',
                            title: 'Driver Delivery Images',
                            type: 'item',
                            url: '/alldriverdeliveryimages',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'fleet',
                    title: 'Manage fleet',
                    type: 'collapse',
                    children: [
                        {
                            id: 'fleet',
                            title: 'Fleet Schedule',
                            type: 'item',
                            url: '/fleet'
                        },
                        {
                            id: 'addvehicles',
                            title: 'Add Vehicles',
                            type: 'item',
                            url: '/fleet/add-vehicles'
                        },
                        {
                            id: 'allvehicles',
                            title: 'Vehicles',
                            type: 'item',
                            url: '/fleet/all-vehicles'
                        }
                    ]
                },
                {
                    id: 'presold-orders',
                    title: 'pre-sold batch',
                    type: 'collapse',
                    children: [
                        {
                            id: 'orders',
                            title: 'PreSold Orders',
                            type: 'item',
                            url: '/pre-sold-orders',
                            breadcrumbs: false
                        },
                        {
                            id: 'pre-sold-orders-batch',
                            title: 'PreSold Batches',
                            type: 'item',
                            url: '/pre-sold-orders/batch',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'logistics',
                    title: 'Logistics',
                    type: 'item',
                    url: '/logistics',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'customer-management',
            title: 'Customer management',
            type: 'collapse',
            icon: icons.SupportAgentIcon,
            children: [
                // {
                //     id: 'retailerDashboard',
                //     title: 'Retailer Profile',
                //     type: 'item',
                //     url: '/retailers/shops',
                //     // url: '/dashboardRetailerIndex',
                //     icon: icons.InsertChartIcon,
                //     breadcrumbs: false
                // },
                {
                    id: 'retailerProfile',
                    title: 'Retailer Profile',
                    type: 'item',
                    url: '/retailers/profile',
                    // url: '/dashboardRetailerIndex',
                    breadcrumbs: false
                },
                {
                    id: 'retailerMap',
                    title: 'Retailer Map',
                    type: 'item',
                    url: '/retailers/map',
                    // url: '/dashboardRetailerIndex',
                    breadcrumbs: false
                },
                {
                    id: 'retailergender',
                    title: 'Retailer Gender',
                    type: 'item',
                    url: '/retailer-gender',
                    breadcrumbs: false
                },
                {
                    id: 'loyalty',
                    title: 'Retailer Loyalty',
                    type: 'item',
                    // url: '/retailers/loyalties/point'
                    url: '/retailer-loyalty'
                },
                // {
                //     id: 'retailer',
                //     title: 'Retail Partners',
                //     type: 'item',
                //     url: '/retailpartners',
                //     breadcrumbs: false
                // },
                {
                    id: 'retailerdebittracker',
                    title: 'Retailer Debit/Credit Tracker',
                    type: 'item',
                    url: '/retailerdebittracker',
                    breadcrumbs: false
                },
                {
                    id: 'salescRM',
                    title: 'Sales CRM',
                    type: 'item',
                    url: '/salescrm',
                    breadcrumbs: false
                },
                {
                    id: 'mira-chat',
                    title: 'Mira Chat',
                    type: 'item',
                    url: '/mira/inbox-messages'
                },
                // {
                //     id: 'verification',
                //     title: 'Verification',
                //     type: 'collapse',
                //     children: [
                //         {
                //             id: 'stage1',
                //             title: 'Stage One',
                //             type: 'item',
                //             url: '/verification'
                //         },
                //         {
                //             id: 'stage2',
                //             title: 'Stage Two',
                //             type: 'item',
                //             url: '/verification/second-stage'
                //         }
                //     ]
                // },

                {
                    id: 'messages',
                    title: 'Messaging',
                    type: 'collapse',
                    children: [
                        {
                            id: 'allmessages',
                            title: 'Send Messages',
                            type: 'item',
                            url: '/allmessages',
                            breadcrumbs: false
                        },

                        {
                            id: 'messagegroups',
                            title: 'Create Messaging Group',
                            type: 'item',
                            url: '/messagegroups',
                            breadcrumbs: false
                        }
                    ]
                }
            ]
        },
        {
            id: 'configuration',
            title: 'Configuration',
            type: 'collapse',
            icon: icons.ManageHistoryIcon,
            children: [
                {
                    id: 'statuses',
                    title: 'Setups',
                    type: 'collapse',

                    children: [
                        {
                            id: 'orderStatus',
                            title: 'Order Statuses',
                            type: 'item',
                            url: '/orderStatus'
                        },
                        {
                            id: 'attribute',
                            title: 'Attribute',
                            type: 'item',
                            url: '/attribute'
                        },
                        // {
                        //     id: 'tags',
                        //     title: 'Tag',
                        //     type: 'item',
                        //     url: '/tags'
                        // },
                        {
                            id: 'loyaltylist',
                            title: 'Loyalty',
                            type: 'item',
                            url: '/loyaltylist'
                        },
                        {
                            id: 'servicestatus',
                            title: 'Service Statuses',
                            type: 'item',
                            url: '/servicestatus'
                        },
                        {
                            id: 'stagingarea',
                            title: 'Staging Area',
                            type: 'item',
                            url: '/stagingarea'
                        },
                        // {
                        //     id: 'darkstorearea',
                        //     title: 'Dark Store Area',
                        //     type: 'item',
                        //     url: '/darkstorearea'
                        // },
                        {
                            id: 'paymentStatus',
                            title: 'Payment Status',
                            type: 'item',
                            url: '/paymentStatus'
                        },
                        {
                            id: 'stage',
                            title: 'Stage',
                            type: 'item',
                            url: '/stage'
                        },
                        {
                            id: 'batchautomation',
                            title: 'Batch Automation',
                            type: 'item',
                            url: '/batchautomation'
                        },
                        {
                            id: 'priorityautomation',
                            title: 'Priority Automation',
                            type: 'item',
                            url: '/priorityautomation'
                        },
                        {
                            id: 'batchstatus',
                            title: 'Batch Status',
                            type: 'item',
                            url: '/batchstatus'
                        },
                        {
                            id: 'ordertype',
                            title: 'OrderType',
                            type: 'item',
                            url: '/ordertype'
                        },
                        {
                            id: 'pickdrop',
                            title: 'Pickup / Drop off Type',
                            type: 'item',
                            url: '/pickdrop'
                        },
                        {
                            id: 'contactorder',
                            title: 'Order Emails',
                            type: 'item',
                            url: '/contactorder'
                        },
                        {
                            id: 'leademail',
                            title: 'Lead Emails',
                            type: 'item',
                            url: '/leademail'
                        },
                        {
                            id: 'collectionemail',
                            title: 'Collection Email',
                            type: 'item',
                            url: '/collectionemail'
                        },
                        {
                            id: 'fleetemailorder',
                            title: 'Fleet Email',
                            type: 'item',
                            url: '/fleetemailorder'
                        },
                        {
                            id: 'country',
                            title: 'Country',
                            type: 'item',
                            url: '/country'
                        },
                        {
                            id: 'city',
                            title: 'City',
                            type: 'item',
                            url: '/city'
                        },

                        {
                            id: 'warehouse',
                            title: 'Warehouse/Inventory',
                            type: 'item',
                            url: '/warehouse',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'partzones',
                    title: 'Part Zones',
                    type: 'collapse',

                    children: [
                        {
                            id: 'partlistname',
                            title: 'Part List Name',
                            type: 'item',
                            url: '/partlistnames'
                        },
                        {
                            id: 'partzones',
                            title: 'Parts Zones',
                            type: 'item',
                            url: '/partzones'
                        }
                    ]
                },

                {
                    id: 'parts',
                    title: 'Parts',
                    type: 'collapse',

                    children: [
                        {
                            id: 'masterdb',
                            title: 'Master Database',
                            type: 'item',
                            url: '/parts'
                        },
                        // {
                        //     id: 'fulpartlist',
                        //     title: 'UnApproved Parts',
                        //     type: 'item',
                        //     url: '/fulpartlist'
                        // },
                        {
                            id: 'garageparts',
                            title: 'Vin Parts',
                            type: 'item',
                            url: '/partsgarage'
                        },
                        {
                            id: 'fleetpart',
                            title: 'Part (Fleet)',
                            type: 'item',
                            url: '/allfleetpart'
                        }
                    ]
                },
                {
                    id: 'user',
                    title: 'User Management',
                    type: 'collapse',

                    children: [
                        {
                            id: 'role',
                            title: 'Roles',
                            type: 'item',
                            url: '/roles'
                        },
                        {
                            id: 'users',
                            title: 'User',
                            type: 'item',
                            url: '/users'
                        }
                    ]
                },
                {
                    id: 'channelpartners',
                    title: 'Channel Partners',
                    type: 'collapse',

                    children: [
                        {
                            id: 'retailers',
                            title: 'Retail',
                            type: 'item',
                            url: '/retail'
                        },
                        // {
                        //     id: 'distributor',
                        //     title: 'Distributor',
                        //     type: 'item',
                        //     url: '/distributor'
                        // },
                        {
                            id: 'mechanic',
                            title: 'Mechanic',
                            type: 'item',
                            url: '/mechanic'
                        },
                        {
                            id: 'fleetpartner',
                            title: 'Fleet',
                            type: 'item',
                            url: '/fleetpartner'
                        },
                        {
                            id: 'channel',
                            title: 'Channel',
                            type: 'item',
                            url: '/channel'
                        }
                    ]
                },
                {
                    id: 'team',
                    title: 'Team',
                    type: 'collapse',

                    children: [
                        {
                            id: 'salesteam',
                            title: 'Sales Team',
                            type: 'item',
                            url: '/salesteam'
                        },
                        {
                            id: 'customersuccessteam',
                            title: 'Customer Success Team',
                            type: 'item',
                            url: '/customersuccessteam'
                        },
                        {
                            id: 'carrier',
                            title: 'Fulfillment Team',
                            type: 'item',
                            url: '/carrier',
                            icon: icons.IconKey
                        }
                    ]
                },
                {
                    id: 'salesdrive',
                    title: 'Sales Pods',
                    type: 'collapse',
                    children: [
                        {
                            id: 'salespod',
                            title: 'Sales Pod',
                            type: 'item',
                            url: '/salespod',
                            breadcrumbs: false
                        }
                    ]
                },
                {
                    id: 'salesplanner',
                    title: 'Sales Planner',
                    type: 'collapse',

                    children: [
                        {
                            id: 'targets',
                            title: 'Target',
                            type: 'item',
                            url: '/targetlist'
                        },
                        {
                            id: 'todolist',
                            title: 'TodoList',
                            type: 'item',
                            url: '/todolist'
                        }
                    ]
                },
                // {
                //     id: 'permission',
                //     title: 'Permission',
                //     type: 'item',
                //     icon: icons.GroupsIcon,
                //     url: '/permission'
                // }
                {
                    id: 'sleepover',
                    title: 'Sleep over',
                    type: 'item',
                    url: '/rmcc/sleepover'
                }
            ]
        },
        {
            id: 'compliance',
            title: 'Compliance',
            type: 'collapse',
            icon: icons.ContentPasteIcon,
            children: [
                {
                    id: 'checkIn',
                    title: 'Check In',
                    type: 'item',
                    url: '/check-in',
                    breadcrumbs: false
                },
                {
                    id: 'checkinlist',
                    title: 'Check in list',
                    type: 'item',
                    url: '/checkins'
                }
            ]
        },
        {
            id: 'data-science',
            title: 'Reporting & analytics',
            type: 'collapse',
            icon: icons.InsightsIcon,
            children: [
                {
                    id: 'cohort',
                    title: 'Cohort Tracker',
                    type: 'item',
                    url: '/cohort'
                },
                {
                    id: 'growthview',
                    title: 'Growth View',
                    type: 'item',
                    url: '/growthview'
                }
            ]
        }
    ]
};

export default utilities;
